import version from 'environments-version.json';
import { EnvironmentConfig } from '@rs-shared-lib/modules/rs-core/models/apps-environment';

export const environment: EnvironmentConfig = {
  production: false,
  apiUrl: 'https://fms3.tst.rentasolutions.org',
  useMockedServices: false,
  enableSentryMonitoring: true,
  sentryReleaseName: 'test-fms-ui-12338',
  environment: 'test',
  authUrl: 'https://authentication.tst.rentasolutions.org',
  topBarCdn: 'https://cdn.rentasolutions.org/topbar/tst/v1.3/',
  topBarEnvironment: 'tst',
  weblateEnvironment: 'tst',
  version: version?.test
};


